$(document).ready(function(){
	// ajax_fetch_coupons( 'product' );
});

function ajax_fetch_coupons( type ) {
	var data = {};
	var itemHolder = {};
	if ( type == 'product' ) {
		data.products_id = $('.j-add-product-form > input[name=products_id]').val();
		itemHolder = $('.product-info__price');
	} else if ( type == 'order' ) {
		data.products_id = $('#fast-order-form').data('productsId');
		itemHolder = $('.j-fast-form .item-price');
	} else if ( type == 'shops' ) {
		data.products_id = $('.j-shops-form').data('productsId');
		itemHolder = $('.j-shops-form .item-price');
	} else {
		return;
	}
	data.action = 'recalc_price';
	$.ajax({
		type: "GET",
		url: "/ajax_fetch_coupons.php",
		data: data,
		dataType : "json",
		success: function( msg ){
			var CurPrice = parseInt( $('.price', itemHolder).html().replace(/\D+/g,"") );
					
			if( msg !== null ) {

				if( CurPrice != msg['price'] && msg['price'] != 0 ){
					// ������ ����
					$('.price', itemHolder).html(msg['price'] + ' <span class="cur">' + PROSKTR.currencies.right + '</span>' );
					var pct = Math.round( (msg['start_price'] - msg['price'])*20/msg['start_price'] ) * 5;
					
					if( $('.old-price', itemHolder).length > 0 ){
						if ( pct > 0 ) {
							$('.old-price-pct', itemHolder).html( '(-' + pct + '%' + ( msg['coupons'] ? msg['withCoupon'] : msg['withAction'] ) + ')' );
						}
					}else{
						$(itemHolder).append('<span class="old-price">' + msg['start_price'] + ' <span class="cur">' + PROSKTR.currencies.right + '</span></span>' + ( pct > 0 ? '<span class="old-price-pct"> (-' + pct +'%' + ( msg['coupon_work'] != '' ? ( msg['coupons'] ? msg['withCoupon'] : msg['withAction'] ) : '' ) + ')</span>' : ''));
					}
				}
			}
		}
	});
}